@import "~react-image-gallery/styles/css/image-gallery.css";

.image-gallery-image {
  max-height: 600px !important;
 }

body {
  background-color: #EEE;
  font-family: 'Roboto';
}

h1 {
  font-family: 'Roboto';
  margin-top: 0;
}

.content {
  max-width: 900px;
  margin-top: 16pt;
  margin-left: auto;
  margin-right: auto;
  padding: 16pt;
  padding-bottom: 4pt;
}

.block {
  width: 70px!important;
  height: 70px !important;
}

.image {
  width: auto;
  height: 300px;
  
}

h4 {
  font-weight: 500;
  margin-bottom: 0pt;
  margin-top: 16pt;
}

p {
  margin-top: 8pt;
  font-weight: 300;
}

.image-gallery-thumbnails-container {
  display: flex;
  padding-left: 8pt;
}

.image-gallery-thumbnail {
  flex-shrink: 0;
}

div > label {
  max-width: none !important;
}

